
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { ApiDebtor } from "@/core/api";
import { DebtorOption } from "@/core/directive/interface/debtor";
import { OCPSVatReportTableFilter } from "@/core/directive/interface/finacialReport";

export default defineComponent({
  name: "transaction-report-finacial-report-ocps-ss-sales-receivables-dropdown",
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref<OCPSVatReportTableFilter>({
      st_settlement_entity_id: "",
      related_invoice_number: "",
      order_number: "",
      invoice_number: "",
      invoice_date_start: "",
      invoice_date_end: "",
    });

    const options = ref({
      entityLoading: false,
      entityOptions: [] as DebtorOption[],
    });

    const getTaggingData = async () => {
      // const { data } = await ApiBase.getTaggingData({
      //   short_key: [
      //     "payment_gateway",
      //     "ocps_order_type",
      //     "settlement_invoice_status",
      //   ],
      // });
      // if (data.code == 0) {
      //   options.value.custom3Options = data.data.ocps_order_type.items;
      //   options.value.statusOptions = data.data.settlement_invoice_status.items;
      //   options.value.paymentGatewayOptions = data.data.payment_gateway.items;
      // }
    };

    const getEntityData = async (value) => {
      options.value.entityLoading = true;
      const { data } = await ApiDebtor.searchDebtor({
        value: value,
      });
      options.value.entityLoading = false;
      if (data.code == 0) {
        options.value.entityOptions = data.data;
      }
    };

    const debounceEntitySearch = _.debounce(getEntityData, 1000);

    const searchEntityItems = (query: string) => {
      debounceEntitySearch(query);
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      getTaggingData();
    });

    return {
      t,
      formData,
      formRef,
      options,
      searchEntityItems,
      submit,
      handleReset,
    };
  },
});
